@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: 'Geist', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  overscroll-behavior: none;
  box-sizing: border-box;
}

code {
  font-family: Menlo, Monaco, 'Courier New', monospace;
}

@font-face {
  font-family: "Geist";
  src: url('./fonts/GeistVariableVF.ttf') format('truetype'),
      url('./fonts/GeistVariableVF.woff2') format('woff2');
}

/* Add your Tailwind CSS styles here */

.radial-gradient {
  background: radial-gradient(100% 100% at 50% 0%, rgba(252, 238, 210, 0.20) 0%, rgba(252, 238, 210, 0.00) 81.04%);
  filter: blur(4px);
}

.coffee-gradient {
  background: radial-gradient(100% 100% at 50% 0%, #302103 0%, rgba(48, 33, 3, 0.40) 81.04%);
  backdrop-filter: blur(7px);
}


@keyframes slideIn {
  from {
    transform: translateX(10%) scale(0.95);
  }
  to {
    transform: translateX(0) scale(1);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 100;
  }
}

.animated-image {
  animation: fadeIn .4s ease-in-out, slideIn .6s ease-out;
}

.title-group {
  animation: fadeIn .5s ease-out 2s;
}

.artwork-bg {
  animation: fadeIn 0.5s ease-in-out;
}
